<template>
    <ButtonGroup :class="buttonGroupClass">
        <SearchAutocomplete
            id="searchText"
            v-model="searchText"
            :placeholder="props.placeholder"
            :headers
            :autofocus="props.isHome"
            select-on-focus
            custom-list-class="search-menu-list"
            custom-main-class="h-full w-full grow"
            :custom-container-class="props.isHome ? 'home-search-dropdown' : 'search-dropdown top-12'"
            :custom-class="autocompleteClass"
            show-min-length-message
            :results="searchResults"
            @search="onSearch"
            @select="onSelect"
            @enter="executeSearch"
            @clear="onClear" />
        <Button
            id="searchSubmitButton"
            variant="white"
            fill="solid"
            :class="buttonClass"
            :title="$t('Layout.SearchButtonTitle')"
            @click="onSearchClick">
            <span
                class="fas fa-magnifying-glass text-gray-400"
                :class="props.isHome ? 'text-2xl' : 'text-lg'"
                aria-hidden="true" />
        </Button>
    </ButtonGroup>
</template>

<script setup lang="ts">
const globalConfig = useStateGlobalConfig();
const searchNavigate = useSearchNavigate();
const { autocomplete } = useAutocomplete();
const props = defineProps({
    isHome: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    region: {
        type: String,
        default: null,
    },
});

const searchResults = ref<AutocompleteItem[]>([]);
const searchText = ref("");

const headers = {
    1: "Search.Autocomplete_Header_PartNumber",
    2: "Search.Autocomplete_Header_PartWithManufacturer",
    3: "Search.Autocomplete_Header_Manufacturer",
    4: "Search.Autocomplete_Header_PartCategory",
    5: "Search.Autocomplete_Header_ProductSeries",
};

const buttonGroupClass = computed(() => `w-full relative "${props.isHome ? "h-20" : "h-12"}`);
const autocompleteClass = computed(
    () =>
        `w-full  ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary border-0 ${
            props.isHome ? "text-2xl px-6 h-20 rounded-l-md" : "h-12 text-lg rounded-l-sm"
        }`
);
const buttonClass = computed(
    () => `focus-visible:ring-offset-0 ${props.isHome ? "w-20 h-20 rounded-r-md" : "h-12 rounded-r-sm"}`
);

function onClear() {
    searchResults.value = [];
}

async function onSearch(searchTerm: string) {
    searchResults.value = await autocomplete(searchTerm);
}

async function onSelect(item: AutocompleteItem) {
    searchText.value = item.label;
    const result = searchResults.value.find((x) => x.label == item.label && x.category == item.category);
    // only manufacturer and part category results will go directly to specific urls, everything else treat as a search.
    if (result && result.url) {
        await navigateTo(result.url, { external: true });
    } else {
        executeSearch(item.label);
    }
}

function onSearchClick() {
    searchNavigate(searchText.value);
}

function executeSearch(searchTerm: string) {
    searchNavigate(searchTerm);
}

onMounted(() => {
    searchText.value = globalConfig.value.SearchText;
});
</script>
