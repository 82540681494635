<template>
    <ButtonGroup class="grow transition-width relative">
        <SearchAutocomplete
            id="searchTextMobileInput"
            v-model="searchText"
            :placeholder="$t('Search.FilterSearch_Watermark')"
            :headers
            :autofocus="props.isHome"
            select-on-focus
            custom-list-class="search-menu-list"
            custom-main-class="flex w-full"
            :custom-container-class="autocompleteClass"
            custom-class="w-full pl-3 pr-0 rounded-l ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary border-0"
            show-min-length-message
            :results="searchResults"
            @search="onSearch"
            @select="onSelect"
            @enter="executeSearch"
            @clear="onClear"
            @focus="onFocus"
            @blur="onBlur" />
        <Button
            id="searchSubmitButtonMobile"
            variant="white"
            fill="solid"
            class="focus-visible:ring-offset-0"
            :title="$t('Layout.SearchButtonTitle')"
            @click="onSearchClick">
            <Icon name="magnifying-glass" class="text-gray-400" />
        </Button>
    </ButtonGroup>
</template>

<script setup lang="ts">
const globalConfig = useStateGlobalConfig();
const searchNavigate = useSearchNavigate();
const { autocomplete } = useAutocomplete();
const props = defineProps({
    isHome: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    region: {
        type: String,
        default: null,
    },
});

const searchResults = ref<AutocompleteItem[]>([]);
const searchText = ref("");

const headers = {
    1: "Search.Autocomplete_Header_PartNumber",
    2: "Search.Autocomplete_Header_PartWithManufacturer",
    3: "Search.Autocomplete_Header_Manufacturer",
    4: "Search.Autocomplete_Header_PartCategory",
    5: "Search.Autocomplete_Header_ProductSeries",
};

const autocompleteClass = computed(() => (props.isHome ? "home-search-dropdown top-10" : "search-dropdown top-10"));

function onClear() {
    searchResults.value = [];
}

async function onSearch() {
    searchResults.value = await autocomplete(searchText.value);
}

async function onSelect(item: AutocompleteItem) {
    searchText.value = item.label;
    const result = searchResults.value.find((x) => x.label == item.label && x.category == item.category);
    // only manufacturer and part category results will go directly to specific urls, everything else treat as a search.
    if (result?.url) {
        await navigateTo(result.url, { external: true });
    } else {
        executeSearch(searchText.value);
    }
}

function onSearchClick() {
    searchNavigate(searchText.value);
}

function executeSearch(searchTerm: string) {
    searchNavigate(searchTerm);
}

function onFocus() {
    const innerNav = document.querySelector("nav.inner");
    if (innerNav) {
        innerNav.classList.add("open");
    }
}

function onBlur() {
    const innerNav = document.querySelector("nav.inner");
    if (innerNav) {
        innerNav.classList.remove("open");
    }
}

onMounted(() => {
    searchText.value = globalConfig.value.SearchText;
});
</script>
